import { Button, Toolbar } from '@progress/kendo-react-buttons';
import { Typography } from '@progress/kendo-react-common';
import { ExpansionPanel, ExpansionPanelActionEvent, ExpansionPanelContent, StackLayout, TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import React, { CSSProperties, ReactNode, useState } from 'react';
import { useGetCommandsQuery, useGetDefaultQuery, useGetMapsQuery } from '../../generated/graphql';
import styles from './Settings.module.css';
import { Reveal } from '@progress/kendo-react-animation';
import EditDefaultDialog from '../../components/dialogs/EditDefaultDialog/EditDefaultDialog';
import { Link } from 'react-router-dom';
import Tags from './Tags/Tags';
import Groups from './Groups/Groups';
import HealthChecks from './HealthChecks/HealthChecks';

type SettingsProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    className?: string;
};

const Settings = (props: SettingsProps) => {
    const { style, className } = props;
    const [selected, setSelected] = useState<number>(0);
    const [searchCommand, setSearchCommand] = useState("");
    const [searchMap, setSearchMap] = useState("");
    const [expanded, setExpanded] = useState(true);
    const [editDefaultVisible, setEditDefaultVisible] = useState(false);

    const defaultSetting = useGetDefaultQuery();

    const isProjSubNameEnabled = defaultSetting?.data?.Default?.isProjSubNameEnabled ? 
                              defaultSetting?.data?.Default?.isProjSubNameEnabled : false; 

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
    };

    const commandData = useGetCommandsQuery({
        variables: {
            contains: searchCommand
        }
    });

    const mapData = useGetMapsQuery({
        variables: {
            contains: searchMap
        }
    });

    const handleEditDefaultOnClose = () => {
        setEditDefaultVisible(false);
        defaultSetting.refetch();
    };

    return (
        <>
            <StackLayout className={styles.Settings} style={style} data-testid="Settings"
                orientation='vertical'
                align={{ horizontal: "stretch", vertical: "top" }}>
                <Typography.h2 style={{ margin: "10px", flex: 0 }}>
                    Global Settings
                </Typography.h2>
                <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px", borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "rgba(0, 0, 0, 0.08)" }}>
                    <Button icon="edit" fillMode="flat" themeColor={"primary"} onClick={() => setEditDefaultVisible(true)}>
                        Edit
                    </Button>
                </Toolbar>
                <ExpansionPanel
                    title="Essentials"
                    style={{ borderWidth: "1px 0" }}
                    expanded={expanded}
                    tabIndex={0}
                    onAction={(event: ExpansionPanelActionEvent) => {
                        setExpanded(!expanded);
                    }}
                >
                    <Reveal>
                        {expanded && (
                            <ExpansionPanelContent>
                                <div className="content">
                                    <StackLayout style={{ margin: "0 10px" }} gap="5px" orientation='horizontal' align={{ horizontal: "start", vertical: "top" }}>
                                        <StackLayout orientation='vertical' gap="5px" align={{ horizontal: "start", vertical: "top" }}>
                                            <span style={{ fontWeight: "bold", opacity: 0.8 }}>{`Default Client: `}</span>
                                            <span style={{ fontWeight: "bold", opacity: 0.8 }}>{`Default Library: `}</span>
                                            <span style={{ fontWeight: "bold", opacity: 0.8 }}>{`Project Sub-name: `}</span>
                                        </StackLayout>
                                        <StackLayout orientation='vertical' gap="5px" align={{ horizontal: "start", vertical: "top" }}>
                                            <Link to={`/clients/${defaultSetting?.data?.Default?.client?.id}`}>{defaultSetting?.data?.Default?.client?.name}</Link>
                                            <Link to={`/clients/${defaultSetting?.data?.Default?.client?.id}/library/${defaultSetting?.data?.Default?.library?.id}`}>{defaultSetting?.data?.Default?.library?.name}</Link>
                                            <span style={{ opacity: 0.8 }}>
                                            {
                                               isProjSubNameEnabled
                                                    ? `Show by default`
                                                    : `Don't show by default`
                                            }
                                            </span>
                                        </StackLayout>
                                    </StackLayout>
                                </div>
                            </ExpansionPanelContent>
                        )}
                    </Reveal>
                </ExpansionPanel>
                <TabStrip
                    style={{ flex: 1 }}
                    selected={selected}
                    onSelect={handleSelect}
                    tabPosition="top"
                >
                    <TabStripTab title="Tags">
                        <Tags />
                    </TabStripTab>
                    <TabStripTab title="Groups">
                        <Groups />
                    </TabStripTab>
                    <TabStripTab title="Health Checks">
                        <HealthChecks />
                    </TabStripTab>
                </TabStrip>
            </StackLayout >
            <EditDefaultDialog visible={editDefaultVisible} onClose={handleEditDefaultOnClose} />
        </>
    );
};

export default Settings;