import { Toolbar, Button, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import React, { CSSProperties, ReactNode, useContext, useState, useEffect } from 'react';
import { Switch } from '@progress/kendo-react-inputs';
import styles from './Projects.module.css';
import SearchBox from '../../../components/main/SearchBox/SearchBox';
import NewProjectDialog from '../../../components/dialogs/NewProjectDialog/NewProjectDialog';
import { Tag, UserRoleEnum, useGetProjectsQuery, useGetIsProjSubNameEnabled } from '../../../generated/graphql';
import { ClientContext } from '../Clients.Provider';
import DataTable from '../../../components/main/DataTable/DataTable';
import DeleteProjectsDialog from '../../../components/dialogs/DeleteProjectsDialog/DeleteProjectsDialog';
import ShowOnRole from '../../../components/main/ShowOnRole/ShowOnRole';

type ProjectsProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const Projects = (props: ProjectsProps) => {
    const { style } = props;
    const client = useContext(ClientContext);
    const [newProjectVisible, setNewProjectVisible] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [tagIds, setTagIds] = useState<string[]>([]);
    const [deleteProjectsDialogVisible, setDeleteProjectsDialogVisible] = useState(false);

        const defaultProjSubName = useGetIsProjSubNameEnabled();
        const isProjSubNameEnabled = defaultProjSubName?.data?.Default?.isProjSubNameEnabled;
        const [toggle, setToggle] = useState(false);   
        
        useEffect(() => {
                setToggle(isProjSubNameEnabled !== undefined ? isProjSubNameEnabled : false);     
        }, [isProjSubNameEnabled]);
    
        const handleIsProjSubNameEnabledChanged = (event: any) => {
                setToggle(event.value);
             };     

    const result = useGetProjectsQuery(toggle, {
        variables: {
            tagIds: tagIds,
            clientId: client?.data?.Client?.id,
            contains: searchText
        }
    });

    const handleDeleteProjectOnClose = () => {
        setDeleteProjectsDialogVisible(false);
        result.refetch();
    };
    const handleSearchBoxOnChanged = (value: string) => {
        setSearchText(value);
    };
    const handleDataTableOnSelected = (ids: string[]) => {
        setSelectedIds(ids);
    };
    const handleNewProjectDialogOnClose = () => {
        setNewProjectVisible(false);
        result.refetch();
    };
    const handleOnFilterChanged = (tags: Tag[]) => {
        tags && setTagIds(tags.map(t => t.id || ""));
    };

    return (
        <>
            <StackLayout
                className={styles.Projects}
                style={style}
                data-testid="Projects"
                orientation='vertical'
                align={{ horizontal: "stretch", vertical: "top" }}>
                <Toolbar style={{ borderWidth: 0 }}>
                    <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => result.refetch()}>
                        Refresh
                    </Button>
                    <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={client?.data?.Client?.isAdmin || false}>
                        <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setNewProjectVisible(true)}>
                            New Project
                        </Button>
                        <p>Show Project Sub-name:</p>
                        <Switch
                            defaultChecked={isProjSubNameEnabled}
                            onLabel={"On"}
                            offLabel={"Off"}
                            onChange={handleIsProjSubNameEnabledChanged}
                            checked={toggle}            
                         />                       
                        {selectedIds.length > 0 && <>
                            <ToolbarSeparator />
                            <Button icon="delete" fillMode="flat" themeColor={"error"} onClick={() => setDeleteProjectsDialogVisible(true)}>
                                Delete
                            </Button>
                        </>}
                    </ShowOnRole>
                </Toolbar>
                            
                {
                    isProjSubNameEnabled == true ?
                    <DataTable
                    showDefaultColumns
                    style={{ flex: 1 }}
                    isLoading={result.loading}
                    data={result.data?.ProjectList?.nodes}
                    link="/Projects"
                    onSearchBoxChanged={handleSearchBoxOnChanged}
                    onSelected={handleDataTableOnSelected}
                    onFilterChanged={handleOnFilterChanged}
                    customProps={[
                        { id: "projectSubName", name: "Project Sub Name" },
                        { id: "library.name", name: "Library" }
                    ]}
                    fetchMore={() => result.data?.ProjectList?.pageInfo?.hasNextPage && result.fetchMore({
                        variables: {
                            libraryId: client?.data?.Client?.id,
                            contains: searchText,
                            after: result.data?.ProjectList?.pageInfo.endCursor
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return previousResult;
                            const previousContents = result.data?.ProjectList?.nodes || [];
                            const newContents = fetchMoreResult.ProjectList?.nodes || [];
                            return {
                                ...previousResult,
                                ProjectList: {
                                    nodes: [...previousContents, ...newContents],
                                    pageInfo: fetchMoreResult.ProjectList?.pageInfo as any
                                }
                            };
                        }
                    })} />
                    :
                    <DataTable
                    showDefaultColumns
                    style={{ flex: 1 }}
                    isLoading={result.loading}
                    data={result.data?.ProjectList?.nodes}
                    link="/Projects"
                    onSearchBoxChanged={handleSearchBoxOnChanged}
                    onSelected={handleDataTableOnSelected}
                    onFilterChanged={handleOnFilterChanged}
                    customProps={[{ id: "library.name", name: "Library" }]}
                    fetchMore={() => result.data?.ProjectList?.pageInfo?.hasNextPage && result.fetchMore({
                        variables: {
                            libraryId: client?.data?.Client?.id,
                            contains: searchText,
                            after: result.data?.ProjectList?.pageInfo.endCursor
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return previousResult;
                            const previousContents = result.data?.ProjectList?.nodes || [];
                            const newContents = fetchMoreResult.ProjectList?.nodes || [];
                            return {
                                ...previousResult,
                                ProjectList: {
                                    nodes: [...previousContents, ...newContents],
                                    pageInfo: fetchMoreResult.ProjectList?.pageInfo as any
                                }
                            };
                        }
                    })} />
                }

                              
            </StackLayout>
            <NewProjectDialog clientId={client?.data?.Client?.id} visible={newProjectVisible} onClose={handleNewProjectDialogOnClose} />
            <DeleteProjectsDialog ids={selectedIds} visible={deleteProjectsDialogVisible} onClose={handleDeleteProjectOnClose} />
        </>
    );
};

export default Projects;